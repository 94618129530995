import React from 'react'

// import QRPayment from './views/QRPayment'
// import CheckoutFailure from './views/CheckoutFailure'
import CheckoutPayment from './views/CheckoutPayment'
// import CheckoutSuccess from './views/CheckoutSuccess'

const routes = [
  { path: '/fail', component: React.lazy(() => import('./views/CheckoutFailure')) },
  { path: '/error', component: React.lazy(() => import('./views/CheckoutError')) },
  { path: '/success', component: React.lazy(() => import('./views/CheckoutSuccess')) },
  { path: '/processing', component: React.lazy(() => import('./views/CheckoutPolling')) },
  { path: '/qr-payment', component: React.lazy(() => import('./views/QRPayment')) },
  { path: '/', exact: true, component: CheckoutPayment },
  { path: '*', redirect: '/' }
]

export default routes
