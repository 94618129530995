import styled from 'styled-components'

export const PreviewContainer = styled.div`
  position: relative;
  background: #ffffff;
  backdrop-filter: none;
  border-radius: 1rem;
  box-shadow: 0 5px 30px rgba(172, 182, 191, 0.25);

  min-height: 20rem;
  padding: 3rem 5rem 4rem;

  .buttons {
    margin-top: 3.5rem;

    button {
      height: 5.3rem;
      font-size: 2rem;
      font-weight: 500;
      line-height: 100%;

      margin-bottom: 0.8rem;
      :last-of-type {
        margin-bottom: 0;
      }
    }
  }
`

export const PreviewTitle = styled.div`
  width: 100%;
  margin-bottom: 3rem;

  color: #000000;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: normal;
  line-height: 2.9rem;
`

export const PreviewGrid = styled.div`
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: auto 1fr;
  row-gap: 0.8rem;
  margin-bottom: 3rem;

  color: #000000;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  .label {
    color: #a3b1bf;
    font-size: 1.4rem;
    font-weight: 600;
  }
`

export const WarningMessageWrapper = styled.div`
  background: rgba(30, 99, 255, 0.08);
  border-radius: 5px;
  padding: 2rem 2rem 2.5rem;
  margin-top: 3rem;

  color: #454a54;
  font-size: 1.2rem;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
`
