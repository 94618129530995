// noinspection SpellCheckingInspection

import styled from 'styled-components'

import bankTransferLogo from '../../assets/methods/bank-transfer.png'
import bcpCashLogo from '../../assets/methods/bcp-cash.png'
import boletoLogo from '../../assets/methods/boleto.png'
import btvoucherLogo from '../../assets/methods/btvoucher.png'
import cellPowerCashLogo from '../../assets/methods/cell_power.svg'
import creditCardLogo from '../../assets/methods/credit-card.png'
import cryptoLogo from '../../assets/methods/crypto.svg'
import efectyCashLogo from '../../assets/methods/efecty.png'
import enairaLogo from '../../assets/methods/enaira.jpg'
import indianNetBankingLogo from '../../assets/methods/indian-bank.png'
import instantBankTransferLogo from '../../assets/methods/instant-bank-transfer.png'
import interacLogo from '../../assets/methods/interac.png'
import jetonLogo from '../../assets/methods/jeton.png'
import jetonWalletLogo from '../../assets/methods/jeton-wallet.png'
import khipuLogo from '../../assets/methods/khipu.png'
import kingdomWalletLogo from '../../assets/methods/kingdom-wallet.png'
import mpesaLogo from '../../assets/methods/m-pesa.png'
import mobileMoneyLogo from '../../assets/methods/mobile-money.png'
import pagoEfectivoLogo from '../../assets/methods/pago-efectivo.png'
import payIdLogo from '../../assets/methods/pay-id.png'
import pecFlashLogo from '../../assets/methods/pec-flash.png'
import pixLogo from '../../assets/methods/pix.png'
import safetyPayLogo from '../../assets/methods/safetypay.png'
import upiLogo from '../../assets/methods/upi.png'
import yapeLogo from '../../assets/methods/yape.png'

import monetPayLogo from '../../assets/methods/monet-pay.png'
import paycashLogo from '../../assets/methods/paycash_cash.png'
import paynetLogo from '../../assets/methods/paynet_cash.png'
import speiLogo from '../../assets/methods/spei.png'

export const ButtonWrapper = styled.div`
  cursor: pointer;
  position: relative;

  height: 8rem;
  width: 100%;

  display: grid;
  grid-template-columns: 7.2rem 1fr 6rem;
  margin-top: 1.6rem;

  color: #454a54;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  border: 1px solid #e1e5ea;
  border-radius: 1rem;

  &.selected {
    background-color: #e9eef5;
    border-color: #e9eef5;

    & .arrow {
      display: flex;
    }
  }
`

export const ButtonImage = styled.span`
  position: relative;
  width: 100%;
  height: 100%;

  background-position: center center;
  background-repeat: no-repeat;
  background-size: 70%;

  border-radius: 1rem;

  //prettier-ignore
  &.image-bankwire {
    background-image: url("${bankTransferLogo}");
    background-size: unset;
  }
  //prettier-ignore
  &.image-bcp_cash {
    background-image: url("${bcpCashLogo}");
  }
  //prettier-ignore
  &.image-boleto {
    background-image: url("${boletoLogo}");
  }
  //prettier-ignore
  &.image-btvoucher {
    background-image: url("${btvoucherLogo}");
  }
  //prettier-ignore
  &.image-cell_power_cash {
    background-image: url("${cellPowerCashLogo}");
  }
  //prettier-ignore
  &.image-credit_card {
    background-image: url("${creditCardLogo}");
  }
  //prettier-ignore
  &.image-instant_crypto {
    background-image: url("${cryptoLogo}");
  }
  //prettier-ignore
  &.image-efecty_cash {
    background-image: url("${efectyCashLogo}");
  }
  //prettier-ignore
  &.image-e_naira {
    background-image: url("${enairaLogo}");
  }
  //prettier-ignore
  &.image-indian_net_banking {
    background-image: url("${indianNetBankingLogo}");
  }
  //prettier-ignore
  &.image-instant_bank_transfer,
  &.image-direct_instant_bank_transfer,
  &.image-community_instant_bank_transfer {
    background-image: url("${instantBankTransferLogo}");
    background-size: unset;
  }
  //prettier-ignore
  &.image-interac {
    background-image: url("${interacLogo}");
  }
  //prettier-ignore
  &.image-jeton,
  &.image-jeton_cash {
    background-image: url("${jetonLogo}");
  }
  //prettier-ignore
  &.image-jeton_wallet {
    background-image: url("${jetonWalletLogo}");
  }
  //prettier-ignore
  &.image-khipu {
    background-image: url("${khipuLogo}");
  }
  //prettier-ignore
  &.image-kingdom_wallet {
    background-image: url("${kingdomWalletLogo}");
    background-size: unset;
  }
  //prettier-ignore
  &.image-m_pesa {
    background-image: url("${mpesaLogo}");
  }
  //prettier-ignore
  &.image-mobile_money {
    background-image: url("${mobileMoneyLogo}");
  }
  //prettier-ignore
  &.image-monet_pay_bank,
  &.image-monet_pay_cash {
    background-image: url("${monetPayLogo}");
    background-size: 70%;
  }
  //prettier-ignore
  &.image-pago_efectivo_bank,
  &.image-pago_efectivo_cash {
    background-image: url("${pagoEfectivoLogo}");
  }
  //prettier-ignore
  &.image-paycash_cash {
    background-image: url("${paycashLogo}");
  }
  //prettier-ignore
  &.image-paynet_cash {
    background-image: url("${paynetLogo}");
  }
  //prettier-ignore
  &.image-pay_id {
    background-image: url("${payIdLogo}");
    background-size: 55%;
  }
  //prettier-ignore
  &.image-pec_flash {
    background-image: url("${pecFlashLogo}");
  }
  //prettier-ignore
  &.image-pix {
    background-image: url("${pixLogo}");
  }
  //prettier-ignore
  &.image-safetypay_bank,
  &.image-safetypay_cash {
    background-image: url("${safetyPayLogo}");
  }
  //prettier-ignore
  &.image-spei {
    background-image: url("${speiLogo}");
  }
  //prettier-ignore
  &.image-upi {
    background-image: url("${upiLogo}");
  }
  //prettier-ignore
  &.image-yape {
    background-image: url("${yapeLogo}");
  }

  &.icon {
    width: 4.2rem;
    height: 3rem;
    padding: 0;
    margin: 0;
    background-position: right center;
    background-color: transparent;
    background-size: contain;
    border-radius: 0;
  }
`

export const ButtonContent = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  padding-right: 1rem;

  .description {
    font-size: 1.2rem;
    font-weight: 400;
  }
`

export const ButtonArrow = styled.div`
  height: 100%;
  width: 100%;

  background: #1e63ff;
  border: 1px solid #1e63ff;
  border-radius: 0 1rem 1rem 0;

  display: none;
  align-items: center;
  justify-content: center;
`
