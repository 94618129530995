import styled from 'styled-components'

export const WarningWrapper = styled.div``

export const WarningContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin-bottom: 4rem;
`

export const WarningTitle = styled.div`
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 2.8rem;
  text-align: center;
  margin: 4rem 0;
  padding: 0 4rem;
`

export const WarningContent = styled.div`
  border-radius: 1rem;
  background: rgba(30, 99, 255, 0.08);

  color: #454a54;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 2rem;

  padding: 2rem 3.8rem 2rem 2.8rem;
`

export const WarningButtons = styled.div`
  width: 100%;
  text-align: center;

  .button {
    height: 5rem;
    width: 100%;
    max-width: 40rem;

    font-size: 1.6rem;
    font-weight: 500;
  }
`
