const host: string = typeof window !== 'undefined' ? window.location.host : ''

const isLocalhost: boolean = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
)

export const isDevelopment: boolean = host.indexOf('-dev.') > -1
export const isSandbox: boolean = host.indexOf('-sandbox.') > -1
export const isProduction: boolean = typeof window !== 'undefined' && !isLocalhost && !isSandbox && !isDevelopment

const settings = {
  backendForLocalhost: 'https://pay-dev.banky.io',
  // backendForLocalhost: 'https://pay-sandbox.banky.io',
  cardGatewayForLocalhost: 'https://card-dev.banky.io',
  // cardGatewayForLocalhost: 'https://card-sandbox.banky.io',
  languages: ['en', 'de', 'es', 'fr', 'hr', 'it', 'pl', 'pt', 'fi', 'sv', 'ja']
}

export default settings
