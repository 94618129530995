import React from 'react'

// noinspection ES6CheckImport
import domPurify from 'dompurify'
import parse from 'html-react-parser'

import { SuccessIcon } from '../../../icons'
import { PreviewMessageStyled } from './PreviewMessage.styled'

import { tr } from 'mmfintech-commons'

interface PreviewMessageProps {
  result: string
}

export const PreviewMessage: React.FC<PreviewMessageProps> = ({ result }) => {
  const message = result.replace(/\r?\n/g, '<br/>').replace(/\\n/g, '<br/>')

  return (
    <PreviewMessageStyled>
      <SuccessIcon />
      <div>
        <div className='title'>{tr('FRONTEND.DEPOSIT.RESULT.SUCCESS', 'Success')}</div>
        {parse(domPurify.sanitize(message))}
      </div>
    </PreviewMessageStyled>
  )
}
