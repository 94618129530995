import styled from 'styled-components'

export const MessageWrapper = styled.div`
  background: #ffffff;
  border-radius: 1rem;
  box-shadow: 0 5px 30px rgba(172, 182, 191, 0.25);
  margin-bottom: 2rem;
  margin-top: -2rem;
  padding: 3rem 2rem;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  color: #454a54;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media (min-width: 640px) {
    padding: 5rem;
  }
`

export const MessageContent = styled.div`
  margin-top: 3rem;
  text-align: center;
  width: 100%;

  .timer {
    color: #b4bfcb;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: normal;
    margin-top: 1rem;
    margin-bottom: 4rem;
  }

  .button {
    width: 100%;
    border-radius: 1rem;

    font-size: 1.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    &.button-primary,
    &.button-primary:hover {
      background: #5dc488;
      box-shadow: 0 5px 10px 0 rgba(17, 106, 16, 0.25);
    }
    &.button-alternative,
    &.button-alternative:hover {
      background: #ff4c4d;
      box-shadow: 0 5px 10px 0 rgba(141, 33, 33, 0.25);
    }
  }
`
