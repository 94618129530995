import styled from 'styled-components'

export const PreviewMessageStyled = styled.div`
  background: rgba(89, 179, 0, 0.2);
  border: 1px solid rgba(89, 179, 0, 0.2);
  border-radius: 1rem;

  width: 100%;
  margin: 0 1rem;
  max-width: 62rem;
  padding: 2.5rem 3.5rem;

  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;

  color: #333333;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: normal;
  line-height: 2.2rem;

  svg {
    height: 3rem;
    margin-top: 0.2rem;
  }

  .title {
    color: #59b300;
    font-size: 2.4rem;
    font-style: normal;
    font-weight: normal;
    line-height: 3.6rem;
    text-align: left !important;

    margin-bottom: 1rem;
  }
`
