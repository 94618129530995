import styled from 'styled-components'

export const SelectBankWrapper = styled.div``

export const SelectBankContainer = styled.div`
  overflow-x: hidden;

  .error-text {
    color: #909090;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.7rem;
    text-align: center;
    margin: 2rem 0;
  }

  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: #eff3f8;
    box-shadow: 0 4px 4px 0 rgba(155, 155, 155, 0.25) inset;
  }
  &::-webkit-scrollbar-thumb {
    background: #b4bfcb;
    border: 0.8px solid #a3b1bf;
    outline: none;
  }
`

export const ErrorText = styled.div`
  width: 100%;
  max-width: 70rem;

  color: #909090;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.7rem;

  text-align: center;
  margin: 2rem 0;

  &.no-margin {
    margin: 0;
  }
`
