import { useEffect, Suspense, useState } from 'react'

import { useDispatch } from 'react-redux'
import { Route, Switch, Redirect, BrowserRouter as Router, RouteProps } from 'react-router-dom'

import { Toaster } from 'react-hot-toast'
import { ErrorBoundary } from 'react-error-boundary'
import { ReportProblem } from './views/ReportProblem'
import { ThemeProvider } from 'styled-components'
import { GlobalContextProvider } from 'mmfintech-commons'
import { ContentWrapper, ErrorFallback, ModalDialog, SuspenseFallback } from './components'

import theme from './theme'
import routes from './routes'
import settings from './settings'

import { withTranslation } from 'react-i18next'
import { actions, configuration } from 'mmfintech-checkout-commons'

import { ThunkDispatch } from 'redux-thunk'

const AppInner = () => {
  const [modalVisible, setModalVisible] = useState(false)

  const globalContext = {
    modalShow: () => setModalVisible(true)
  }

  useEffect(() => {
    configuration.createBackendConfig(settings.backendForLocalhost)
    if (settings['cardGatewayForLocalhost']) {
      configuration.createCardGatewayConfig(settings['cardGatewayForLocalhost'])
    }
    // eslint-disable-next-line
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <GlobalContextProvider context={globalContext}>
        <Router>
          <ContentWrapper>
            <Switch>
              {routes.map((route, index) => {
                const { path, exact, redirect, component } = route

                if (redirect) {
                  return (
                    <Route key={index} exact={exact} path={path}>
                      <Redirect to={redirect} />
                    </Route>
                  )
                }

                if (component) {
                  return <CustomRoute key={index} path={path} exact={exact} component={component} />
                }

                return null
              })}
            </Switch>

            <ModalDialog
              content={<ReportProblem visible={modalVisible} onClose={() => setModalVisible(false)} />}
              visible={modalVisible}
              onClose={() => setModalVisible(false)}
            />
          </ContentWrapper>

          <Toaster
            position='top-right'
            containerStyle={{
              top: '10rem'
            }}
            toastOptions={{
              className: '',
              style: {
                color: '#000000',
                fontFamily: 'inherit',
                fontSize: '1.4rem',
                fontStyle: 'normal',
                padding: '1.5rem',
                borderRadius: '0'
              },
              success: {}
            }}
          />
        </Router>
      </GlobalContextProvider>
    </ThemeProvider>
  )
}

const ThisApp = withTranslation()(AppInner)

const App = () => {
  const dispatch: ThunkDispatch<Promise<void>, any, any> = useDispatch()

  const handleError = (error: Error, info: { componentStack: string }) => {
    void dispatch(actions.common.errorLogging(error.toString(), info))
  }

  const handleReset = () => {
    window.location.replace('/')
  }

  return (
    <Suspense fallback={<SuspenseFallback />}>
      <ErrorBoundary FallbackComponent={ErrorFallback} onError={handleError} onReset={handleReset}>
        <ThisApp />
      </ErrorBoundary>
    </Suspense>
  )
}

export default App

const CustomRoute = ({ component: Component, ...rest }: RouteProps) => (
  <Route {...rest} render={props => <Component {...props} />} />
)
