import React, { ReactNode } from 'react'
import { useDispatch } from 'react-redux'

import { LanguageMenu } from '../LanguageMenu'
import { ThunkDispatch } from 'redux-thunk'
import { CheckoutContainerStyled, CheckoutContent, CheckoutHeader } from './CheckoutContainer.styled'

import { actions, useSessionId } from 'mmfintech-checkout-commons'

import brandLogo from '../../assets/images/banky-logo-black.png'

interface CheckoutContainerProps {
  children: ReactNode
  className?: string
  hideLanguage?: boolean
}

export const CheckoutContainer: React.FC<CheckoutContainerProps> = ({ className, hideLanguage = false, children }) => {
  const { sessionId } = useSessionId()

  const dispatch: ThunkDispatch<Promise<void>, any, any> = useDispatch()

  const handleLanguageChanged = (languageCode: string): void => {
    void dispatch(actions.common.storeSelectedLanguage(sessionId, languageCode))
  }

  return (
    <CheckoutContainerStyled className={className}>
      <CheckoutContent>
        <CheckoutHeader>
          <span className='logo'>
            <img src={brandLogo} alt='Logo' />
          </span>
          {hideLanguage ? null : <LanguageMenu onChange={handleLanguageChanged} />}
        </CheckoutHeader>

        {children}
      </CheckoutContent>
    </CheckoutContainerStyled>
  )
}
