import React from 'react'

import { Link } from 'react-router-dom'
import { Button } from 'mmfintech-portal-commons'
import { BackIcon } from '../../../icons'
import { BackWrapper, CancelContainer, MethodWrapper } from '../Checkout.styled'
import {
  WarningButtons,
  WarningContainer,
  WarningContent,
  WarningTitle,
  WarningWrapper
} from './BankTransferWarning2.styled'

import { tr } from 'mmfintech-commons'

import WarningIcon from '../../../assets/icons/warning-blue.svg'
import ExclamationIcon from '../../../assets/icons/exclamation.svg'

interface BankTransferWarning2Props {
  handleBack: () => void
  handleNext: () => void
  paymentMethod?: string
  loading?: boolean
  onCancel?: () => void
}

export const BankTransferWarning2: React.FC<BankTransferWarning2Props> = ({
  handleBack,
  handleNext,
  paymentMethod,
  loading,
  onCancel
}) => {
  return (
    <WarningWrapper>
      <BackWrapper>
        <span onClick={handleBack} data-test='back-button'>
          <BackIcon />
          <span>{tr('FRONTEND.BUTTONS.BACK', 'Back')}</span>
        </span>
      </BackWrapper>

      <MethodWrapper data-test={`pay-with-${paymentMethod}-warning2`}>
        {tr('CHECKOUT.PAYMENT.PAY_BY', 'Pay by {{METHOD}}', {
          METHOD: paymentMethod ? tr(`METADATA.PAYMENT_OPTIONS.${paymentMethod}`, paymentMethod) : ''
        })}
      </MethodWrapper>

      <WarningContainer>
        <img src={ExclamationIcon} width={90} height={90} alt='' />

        <WarningTitle>{tr('CHECKOUT.MANUAL.STEP2.TITLE', 'Are you sending from your own account?')}</WarningTitle>
        <WarningContent>
          <img src={WarningIcon} alt='' />
          <span>
            {tr(
              'CHECKOUT.MANUAL.STEP2.WARNING',
              'Only you can pay for this transfer. We will reject the transfer if the funds are sent by someone else'
            )}
          </span>
        </WarningContent>
      </WarningContainer>

      <WarningButtons>
        <Button
          type='button'
          color='primary'
          text={tr('CHECKOUT.MANUAL.STEP2.CONTINUE_BUTTON', 'Continue')}
          onClick={handleNext}
          loading={loading}
          data-test='button-confirm'
        />
        {typeof onCancel === 'function' ? (
          <CancelContainer className='mt-2'>
            <Link to='#' onClick={onCancel} data-test='cancel-button'>
              {tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')}
            </Link>
          </CancelContainer>
        ) : null}
      </WarningButtons>
    </WarningWrapper>
  )
}
