export const SuccessIcon = () => (
  <svg width='90' height='90' viewBox='0 0 90 90' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect width='90' height='90' rx='12.8571' fill='#F6FAFE' />
    <path d='M21.041 39.3252L39.3726 57.657M69.3926 28L34.917 62.4757' stroke='#40C43E' strokeWidth='10' />
    <path
      d='M18 42.4313L39.3248 63.755M72.3938 31.0491L36 67.4429'
      stroke='#5DC488'
      strokeWidth='10'
      strokeLinejoin='bevel'
    />
    <path
      d='M69 53C69 57.4183 65.4183 61 61 61C56.5817 61 53 57.4183 53 53C53 48.5817 56.5817 45 61 45C65.4183 45 69 48.5817 69 53Z'
      fill='#FFCE32'
    />
    <path d='M57 51.7971L60.3669 55.1638M65.588 50L59.8663 55.7218' stroke='#5DC488' strokeWidth='1.5' />
  </svg>
)
