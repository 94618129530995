import { ReactNode } from 'react'

import { ContentWrapperStyled } from './ContentWrapper.styled'

type ContentWrapperProps = {
  children: ReactNode
}

export const ContentWrapper = ({ children }: ContentWrapperProps) => (
  <ContentWrapperStyled>{children}</ContentWrapperStyled>
)
