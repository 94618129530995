import styled from 'styled-components'

export const CheckoutContainerStyled = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
  padding-top: 1rem;
  min-height: 100vh;

  .err-msg-wrapper {
    justify-content: center;
  }
`

export const CheckoutContent = styled.div`
  width: 100%;
  max-width: 50rem;
  min-height: 80vh;
  padding: 0.5rem 1rem;
`

export const CheckoutHeader = styled.div`
  height: 8.2rem;
  width: 100%;
  max-width: 48rem;

  background: linear-gradient(180deg, #ffce32 61.19%, #ffbc3a 74.55%);
  border-radius: 1.4rem 1.4rem 0 0;
  padding: 1.6rem 2rem 4.2rem;

  @media (min-width: 500px) {
    padding: 1.6rem 4rem 3rem;
  }

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  .logo {
    img {
      height: 2.2rem;
    }
  }
`
