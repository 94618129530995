import { ReactElement, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { PencilIcon } from '../../icons'
import { CreditCardAvs } from './CreditCardAvs'
import { CreditCardOtp } from './CreditCardOtp'
import { CreditCardPin } from './CreditCardPin'
import { DepositResult } from '../DepositResult'
import { SelectBankStep } from './SelectBankStep'
import { SelectMethodStep } from './SelectMethodStep'
import { CheckoutContainer } from '../../components'
import { AdditionalFieldsStep } from './AdditionalFieldsStep'
import { ErrorDisplay, Preloader } from 'mmfintech-portal-commons'
import { BankTransferWarning1, BankTransferWarning2 } from './BankTransfer'
import { AmountContainer, PaymentContainer, PaymentContent, ProblemWrapper } from './Checkout.styled'

import settings from '../../settings'

import { formatFloat, getCurrencyDecimals, GlobalContext, isValidString, tr } from 'mmfintech-commons'

import {
  actions,
  CheckoutFlowStep,
  useCheckout,
  useFilteredBanks,
  useLanguageParam,
  useSessionId,
  useUnloadBeacon
} from 'mmfintech-checkout-commons'

import { ThunkDispatch } from 'redux-thunk'
import {
  CgCardPaymentAuthorizationDtlRequest,
  CheckoutMethodField,
  PaymentOption,
  PaymentSessionStatusEnum,
  SupportedBank
} from 'mmfintech-commons-types'

export const Checkout = (): ReactElement => {
  const { modalShow } = useContext(GlobalContext)

  const {
    banksCount,
    checkoutCountries,
    checkoutCountriesError,
    checkoutError,
    checkoutPay,
    checkoutPayFetching,
    checkoutOptions,
    session
  } = useSelector(
    ({
      common: { selectedLanguage },
      checkout: {
        banksCount,
        checkoutCountries,
        checkoutCountriesError,
        checkoutError,
        checkoutPay,
        checkoutPayFetching,
        checkoutOptions,
        session
      }
    }) => ({
      banksCount,
      checkoutCountries,
      checkoutCountriesError,
      checkoutError,
      checkoutPay,
      checkoutPayFetching,
      checkoutOptions,
      selectedLanguage,
      session
    }),
    shallowEqual
  )

  const {
    amount,
    currency,
    foreignTransactionId,
    sessionStatus,
    paymentResult,
    paymentResultType,
    processingAmount,
    processingCurrency,
    showCancelButton
  } = session || {}

  const [triggered, setTriggered] = useState(false)
  const [eventSubmitted, setEventSubmitted] = useState(false)

  const history = useHistory()
  const dispatch: ThunkDispatch<Promise<void>, any, any> = useDispatch()

  const { sessionId } = useSessionId()
  useLanguageParam(settings.languages)
  useUnloadBeacon({ sessionId })

  const {
    formValues,
    initialized,
    fields,
    step,
    setStep,
    logEvent,
    countryCode,
    setCountryCode,
    paymentMethod,
    setPaymentMethod,
    hasBankSelection,
    hasAdditionalFields,
    showFieldsAfterBanks,
    reloadSession,
    jetonWalletSpecialCase,
    handlePay
  } = useCheckout({
    initialStep: CheckoutFlowStep.SELECT_METHOD,
    enableLogging: true,
    preselectSingleBank: true,
    preselectSingleMethod: true,
    enableSpecialJetonWalletCase: true
  })

  const filteredBanks = useFilteredBanks((bank: SupportedBank) => handleBankSelected(bank), 0)

  const isBankTransfer = () => ['BANKWIRE'].includes(paymentMethod)

  const handleCancelClick = () => {
    if (sessionId) {
      void dispatch(actions.checkout.cancelSessionPayment(sessionId))
    }
    logEvent('cancelled_by_payer')
    history.push('/fail')
    return false
  }

  const handleCountryChanged = (value: string): void => {
    if (countryCode !== value) {
      filteredBanks.reset()
      setCountryCode(value)
    }
  }

  const hasMethodSelection = () => checkoutCountries?.length > 1 || checkoutOptions?.length > 1

  const handleContinueAfterMethodSelection = () => {
    if (hasAdditionalFields() && !showFieldsAfterBanks()) {
      setStep(CheckoutFlowStep.ADDITIONAL_FIELDS)
    } else if (hasBankSelection()) {
      setStep(CheckoutFlowStep.SELECT_BANK)
    } else if (hasAdditionalFields() && showFieldsAfterBanks()) {
      setStep(CheckoutFlowStep.ADDITIONAL_FIELDS)
    } else {
      handleSubmit()
    }
  }

  const handleBackFromAdditionalFields = () => {
    dispatch(actions.checkout.cleanupPayment())
    if (showFieldsAfterBanks() && hasBankSelection()) {
      setStep(CheckoutFlowStep.SELECT_BANK)
    } else {
      setStep(CheckoutFlowStep.SELECT_METHOD)
    }
  }

  const handleContinueAfterAdditionalFields = () => {
    if (hasBankSelection() && !showFieldsAfterBanks()) {
      formValues.setRequired('bankChoiceId', false)
    }
    if (formValues.areValid()) {
      if (hasBankSelection() && !showFieldsAfterBanks()) {
        setStep(CheckoutFlowStep.SELECT_BANK)
      } else {
        handleSubmit()
      }
    }
  }

  const handleContinueAfterBankSelected = () => {
    formValues.setRequired('bankChoiceId', true)
    if (showFieldsAfterBanks()) {
      setStep(CheckoutFlowStep.ADDITIONAL_FIELDS)
    } else {
      handleSubmit()
    }
  }

  const handleBackFromBankTransferWarning = () => {
    if (hasAdditionalFields()) {
      setStep(CheckoutFlowStep.ADDITIONAL_FIELDS)
      return
    }
    setStep(CheckoutFlowStep.SELECT_METHOD)
  }

  const handleBackFromBankSelection = () => {
    dispatch(actions.checkout.cleanupPayment())
    if (!showFieldsAfterBanks() && hasAdditionalFields()) {
      setStep(CheckoutFlowStep.ADDITIONAL_FIELDS)
    } else {
      setStep(CheckoutFlowStep.SELECT_METHOD)
    }
  }

  const handleBankSelected = (bank: SupportedBank): void => {
    const id = bank ? bank.bankChoiceId : ''
    formValues.setValue('bankChoiceId', id)
    if (id) {
      logEvent('bank_selected', id)
    }
  }

  const handleSubmit = (onPay = undefined) => {
    if (isBankTransfer()) {
      if (formValues.areValid()) {
        setStep(CheckoutFlowStep.BANK_TRANSFER_WARNING1)
      }
      return
    }

    typeof onPay === 'function' && onPay()
    handlePay()
  }

  const handleSearchFocus = () => logEvent('bank_search_focus', null, true)

  const handleSearchType = () => {
    if (!eventSubmitted) {
      logEvent('bank_search_type', null, true)
      setEventSubmitted(true)
    }
  }

  const handleConfirmPayment = (data: CgCardPaymentAuthorizationDtlRequest): void => {
    void dispatch(actions.checkout.completeCreditCardPayment(sessionId, data))
  }

  const parseResult = () => {
    try {
      return JSON.parse(paymentResult)
    } catch (error) {
      return paymentResult
    }
  }

  const prepareResponse = () =>
    sessionStatus?.value === PaymentSessionStatusEnum.IN_PROGRESS
      ? { result: parseResult(), resultType: paymentResultType, processingAmount, processingCurrency }
      : checkoutPay

  const validMethod = () => jetonWalletSpecialCase || (isValidString(paymentMethod) && checkoutOptions.length === 1)

  const validBankSelection = () => {
    const exists = hasBankSelection()
    return !exists || (exists && banksCount === 1 && isValidString(formValues.getValue('bankChoiceId')))
  }

  const validFields = () => {
    const option = checkoutOptions?.find((v: PaymentOption) => v.paymentMethod === paymentMethod)
    if (option) {
      const { fields } = option
      if (Array.isArray(fields)) {
        const find = fields.find((item: CheckoutMethodField) => {
          const { name, prefilledValue, displayIfPrefilled } = item
          return (!prefilledValue?.length || displayIfPrefilled) && name !== 'bankChoiceId'
        })
        return !find
      }
    }
    return true
  }

  useEffect(() => {
    if (
      initialized &&
      !triggered &&
      !checkoutPayFetching &&
      isValidString(paymentMethod) &&
      // paymentMethod === PaymentMethodEnum.BANKWIRE &&
      validBankSelection() &&
      validFields()
    ) {
      setTriggered(true)
    }
    // eslint-disable-next-line
  }, [initialized, formValues.getValue('bankChoiceId')])

  useEffect(() => {
    if (initialized && !triggered && !checkoutPayFetching && validMethod() && isBankTransfer() && validFields()) {
      setTriggered(true)
    }
  }, [initialized, paymentMethod])

  useEffect(() => {
    if (triggered) {
      handleSubmit()
    }
  }, [triggered])

  useEffect(() => {
    if (checkoutError) {
      const { errorKey } = checkoutError || {}
      if (errorKey === 'MERCHANTS.ERROR.PAYMENT_ALREADY_COMPLETED') {
        reloadSession()
      }
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }
  }, [checkoutError])

  return (
    <CheckoutContainer>
      <ErrorDisplay error={checkoutCountriesError} />

      {!initialized ? (
        <PaymentContainer>
          <Preloader />
        </PaymentContainer>
      ) : (
        <>
          {amount ? (
            <AmountContainer>
              <div className='row amount'>
                <span data-test='amount'>{formatFloat(amount, getCurrencyDecimals(currency))}</span>
                <span className='currency' data-test='currency'>
                  {currency}
                </span>
              </div>
              {foreignTransactionId ? (
                <div className='row'>
                  <span className='label'>{tr('CHECKOUT.PAYMENT.ID', 'ID')}:</span>
                  <span data-test='transaction-id'>{foreignTransactionId}</span>
                </div>
              ) : null}
            </AmountContainer>
          ) : null}

          <PaymentContainer>
            {step !== CheckoutFlowStep.SELECT_BANK &&
            step !== CheckoutFlowStep.SELECT_METHOD &&
            step !== CheckoutFlowStep.ADDITIONAL_FIELDS &&
            step !== CheckoutFlowStep.CREDIT_CARD_AVS &&
            step !== CheckoutFlowStep.CREDIT_CARD_OTP &&
            step !== CheckoutFlowStep.CREDIT_CARD_PIN ? (
              <ErrorDisplay error={checkoutError} />
            ) : null}

            {step === CheckoutFlowStep.SELECT_METHOD && !triggered ? (
              <SelectMethodStep
                specialCase={jetonWalletSpecialCase}
                countryCode={countryCode}
                setCountryCode={handleCountryChanged}
                paymentMethod={paymentMethod}
                setPaymentMethod={setPaymentMethod}
                onContinue={handleContinueAfterMethodSelection}
                showCancel={showCancelButton}
                onCancel={handleCancelClick}
              />
            ) : null}

            {step === CheckoutFlowStep.ADDITIONAL_FIELDS ? (
              <AdditionalFieldsStep
                error={checkoutError}
                backVisible={hasMethodSelection() || (showFieldsAfterBanks() && hasBankSelection())}
                onBack={handleBackFromAdditionalFields}
                countryCode={countryCode}
                paymentMethod={paymentMethod}
                fields={fields}
                formValues={formValues}
                submitCaption={
                  (hasBankSelection() && !showFieldsAfterBanks()) || isBankTransfer()
                    ? tr('FRONTEND.BUTTONS.CONTINUE', 'Continue')
                    : tr('CHECKOUT.PAYMENT.BUTTON_PAY', 'Pay')
                }
                onCancel={handleCancelClick}
                onSubmit={handleContinueAfterAdditionalFields}
                cancelVisible={showCancelButton}
              />
            ) : null}

            {step === CheckoutFlowStep.SELECT_BANK ? (
              <SelectBankStep
                backCaption={
                  hasAdditionalFields() && !showFieldsAfterBanks()
                    ? tr('FRONTEND.BUTTONS.BACK', 'Back')
                    : tr('CHECKOUT.PAYMENT.SWITCH_METHOD', 'Switch method')
                }
                backVisible={hasMethodSelection() || (!showFieldsAfterBanks() && hasAdditionalFields())}
                onBack={handleBackFromBankSelection}
                paymentMethod={paymentMethod}
                countryCode={countryCode}
                formValues={formValues}
                filteredBanks={filteredBanks}
                onContinue={handleContinueAfterBankSelected}
                cancelVisible={showCancelButton}
                onCancel={handleCancelClick}
                handleSearchFocus={handleSearchFocus}
                handleSearchType={handleSearchType}
                specialCase={jetonWalletSpecialCase}
              />
            ) : null}

            {step === CheckoutFlowStep.CREDIT_CARD_AVS && (
              <CreditCardAvs onSubmit={handleConfirmPayment} onCancel={showCancelButton ? handleCancelClick : null} />
            )}
            {step === CheckoutFlowStep.CREDIT_CARD_OTP && (
              <CreditCardOtp onSubmit={handleConfirmPayment} onCancel={showCancelButton ? handleCancelClick : null} />
            )}
            {step === CheckoutFlowStep.CREDIT_CARD_PIN && (
              <CreditCardPin onSubmit={handleConfirmPayment} onCancel={showCancelButton ? handleCancelClick : null} />
            )}

            {step === CheckoutFlowStep.BANK_TRANSFER_WARNING1 ? (
              <BankTransferWarning1
                handleNext={() => setStep(CheckoutFlowStep.BANK_TRANSFER_WARNING2)}
                handleBack={triggered ? undefined : handleBackFromBankTransferWarning}
                paymentMethod={paymentMethod}
                onCancel={showCancelButton ? handleCancelClick : null}
              />
            ) : null}
            {step === CheckoutFlowStep.BANK_TRANSFER_WARNING2 ? (
              <BankTransferWarning2
                handleNext={() => handlePay()}
                handleBack={() => setStep(CheckoutFlowStep.BANK_TRANSFER_WARNING1)}
                paymentMethod={paymentMethod}
                loading={checkoutPayFetching}
                onCancel={showCancelButton ? handleCancelClick : null}
              />
            ) : null}

            {/* TODO: step === CheckoutFlowStep.INTERNAL_EXECUTION <InternalPayment session={checkoutPay} /> */}

            {step === CheckoutFlowStep.DEPOSIT_RESULT ? (
              <PaymentContent>
                <DepositResult
                  response={prepareResponse()}
                  logEvent={logEvent}
                  session={session}
                  sessionId={sessionId}
                />
              </PaymentContent>
            ) : null}
          </PaymentContainer>

          {sessionId ? (
            <ProblemWrapper>
              <span onClick={() => modalShow(null)} data-test='report-a-problem-button'>
                <PencilIcon />
                <span>{tr('CHECKOUT.PROBLEM.TITLE', 'Report a Problem')}</span>
              </span>
            </ProblemWrapper>
          ) : null}
        </>
      )}
    </CheckoutContainer>
  )
}
