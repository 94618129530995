import React from 'react'

import { Button } from 'mmfintech-portal-commons'
import {
  WarningBullet,
  WarningButtons,
  WarningContent,
  WarningTitle,
  WarningWrapper
} from './BankTransferWarning1.styled'

import { tr } from 'mmfintech-commons'

import AttentionIcon from '../../../assets/icons/attention.svg'

import Bullet1 from '../../../assets/icons/bullet11.svg'
import Bullet2 from '../../../assets/icons/bullet12.svg'
import Bullet3 from '../../../assets/icons/bullet13.svg'
import { BackWrapper, CancelContainer, MethodWrapper } from '../Checkout.styled'
import { BackIcon } from '../../../icons'
import { Link } from 'react-router-dom'

interface BankTransferWarning1Props {
  handleBack?: () => void
  handleNext: () => void
  paymentMethod?: string
  onCancel?: () => void
}

export const BankTransferWarning1: React.FC<BankTransferWarning1Props> = ({
  handleBack,
  handleNext,
  paymentMethod,
  onCancel
}) => {
  return (
    <WarningWrapper>
      {typeof handleBack === 'function' ? (
        <BackWrapper>
          <span onClick={handleBack} data-test='back-button'>
            <BackIcon />
            <span>{tr('FRONTEND.BUTTONS.BACK', 'Back')}</span>
          </span>
        </BackWrapper>
      ) : null}

      <MethodWrapper data-test={`pay-with-${paymentMethod}-warning1`}>
        {tr('CHECKOUT.PAYMENT.PAY_BY', 'Pay by {{METHOD}}', {
          METHOD: paymentMethod ? tr(`METADATA.PAYMENT_OPTIONS.${paymentMethod}`, paymentMethod) : ''
        })}
      </MethodWrapper>

      <WarningContent>
        <img src={AttentionIcon} width={90} height={90} alt='' />

        <WarningTitle>
          {tr('CHECKOUT.MANUAL.STEP1.TITLE', "We won't take the money from your bank account automatically.")}
        </WarningTitle>

        <WarningBullet>
          <img src={Bullet1} alt='' />
          <span>{tr('CHECKOUT.MANUAL.STEP1.BULLET1', "We'll provide you with our bank details.")}</span>
        </WarningBullet>
        <WarningBullet>
          <img src={Bullet2} alt='' />
          <span>{tr('CHECKOUT.MANUAL.STEP1.BULLET2', 'You will need to initiate the bank transfer on your own.')}</span>
        </WarningBullet>
        <WarningBullet>
          <img src={Bullet3} alt='' />
          <span>{tr('CHECKOUT.MANUAL.STEP1.BULLET3', 'Once we receive the money, we will credit the funds.')}</span>
        </WarningBullet>
      </WarningContent>
      <WarningButtons>
        <Button
          type='button'
          color='primary'
          text={tr('CHECKOUT.MANUAL.STEP1.CONTINUE_BUTTON', 'I understand')}
          onClick={handleNext}
          data-test='button-confirm'
        />
        {typeof onCancel === 'function' ? (
          <CancelContainer className='mt-2'>
            <Link to='#' onClick={onCancel} data-test='cancel-button'>
              {tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')}
            </Link>
          </CancelContainer>
        ) : null}
      </WarningButtons>
    </WarningWrapper>
  )
}
