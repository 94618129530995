import cn from 'classnames'

import { ButtonArrow, ButtonContent, ButtonImage, ButtonWrapper } from './PaymentMethodButton.styled'

import { tr } from 'mmfintech-commons'
import { ArrowRightIcon } from '../../icons'

type PaymentMethodButtonProps = {
  name: string
  description?: string
  onClick: () => void
  onContinue?: () => void
  selected: boolean
}

export const PaymentMethodButton = ({ name, description, selected, onClick, onContinue }: PaymentMethodButtonProps) => {
  const handleClick = () => {
    if (selected) {
      onContinue()
    } else {
      onClick()
    }
  }

  return (
    <ButtonWrapper className={cn({ selected })} onClick={handleClick} data-test={`method-${name.toLowerCase()}`}>
      <ButtonImage className={cn('image-' + name.toLowerCase(), { selected })} />
      <ButtonContent>
        {tr('METADATA.PAYMENT_OPTIONS.' + name, name)}
        {description && <div className='description'>{description}</div>}
      </ButtonContent>
      <ButtonArrow className='arrow' data-test={`button-${name.toLowerCase()}`}>
        <ArrowRightIcon />
      </ButtonArrow>
    </ButtonWrapper>
  )
}
