import { useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import { Link } from 'react-router-dom'
import { Button, ErrorDisplay, Input } from 'mmfintech-portal-commons'
import { CancelContainer, MethodWrapper, PaymentContent } from '../Checkout.styled'

import { isValidFunction, tr, useFormValues } from 'mmfintech-commons'

import { CreditCardConfirmStepProps } from '../types'

export const CreditCardPin = ({ onSubmit, onCancel }: CreditCardConfirmStepProps) => {
  const { checkoutError, checkoutPayFetching } = useSelector(
    ({ checkout: { checkoutError, checkoutPayFetching } }) => ({
      checkoutError,
      checkoutPayFetching
    }),
    shallowEqual
  )

  const formValues = useFormValues({
    pin: { required: true }
  })

  const handleSubmit = () => {
    if (formValues.areValid()) {
      onSubmit({
        pin: formValues.prepare()
      })
    }
  }

  useEffect(() => {
    return () => {
      formValues.reset()
    }
  }, [])

  return (
    <PaymentContent>
      <MethodWrapper data-test='credit-card-avs'>
        {tr('CHECKOUT.CREDIT_CARD.PIN.TITLE', 'Confirm payment')}
      </MethodWrapper>

      <Input
        type='text'
        data-test='pin-input'
        label={tr('CHECKOUT.CREDIT_CARD.PIN.PIN', 'PIN')}
        {...formValues.registerInput('pin')}
        hideErrorLine
        autoComplete='off'
      />

      <ErrorDisplay error={checkoutError} />

      <div className='buttons'>
        <Button
          type='button'
          color='primary'
          text={tr('CHECKOUT.CREDIT_CARD.COMPLETE_BUTTON', 'Complete payment')}
          onClick={handleSubmit}
          loading={checkoutPayFetching}
          data-test='button-complete'
        />
        {isValidFunction(onCancel) ? (
          <CancelContainer className='mt-1'>
            <Link to='#' onClick={onCancel} data-test='cancel-button'>
              {tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')}
            </Link>
          </CancelContainer>
        ) : null}
      </div>
    </PaymentContent>
  )
}
