import { ReactNode, useEffect, useState } from 'react'
import cn from 'classnames'

import { ThreeCircles } from 'react-loader-spinner'
import { ChevronLeft, ChevronRight } from '../../icons'
import { CustomSelectText, CustomSelectWrapper } from './CustomSelect.styled'

import { tr } from 'mmfintech-commons'

type CustomSelectProps = {
  back?: boolean
  icon?: ReactNode
  caption?: string
  description?: string
  selected?: boolean
  onClick: () => void
  loading: boolean
  maintenance?: boolean
  [key: string]: any
}

export const CustomSelect = (props: CustomSelectProps) => {
  const { back, caption, description, icon, selected, onClick, loading, maintenance, className, ...rest } = props

  const [visible, setVisible] = useState(false)

  const handleClick = () => {
    if (!loading) {
      onClick()
    }
  }

  useEffect(() => {
    setVisible(true)
    return () => {
      setVisible(false)
    }
  }, [])

  return (
    <CustomSelectWrapper
      onClick={handleClick}
      className={cn(className, { visible: visible, back, selected, maintenance })}
      {...rest}>
      {back ? (
        <span className='back-arrow'>
          <ChevronLeft />
        </span>
      ) : (
        <span className='icon'>{icon}</span>
      )}

      <CustomSelectText>
        {caption ? <span className='caption'>{caption}</span> : null}
        {description ? <span>{description}</span> : null}
        {maintenance ? (
          <span className='warning'>{tr('CHECKOUT.VOUCHER.BANKS.MAINTENANCE', 'Under maintenance')}</span>
        ) : null}
      </CustomSelectText>
      {!back && selected ? (
        <span className='arrow'>
          {loading ? <ThreeCircles width='3.5rem' height='3.5rem' color='white' /> : <ChevronRight />}
        </span>
      ) : null}
    </CustomSelectWrapper>
  )
}
