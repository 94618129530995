import styled from 'styled-components'

export const MenuWrapper = styled.div`
  color: #454a54;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: relative;
`

export const MenuContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  .arrow {
    margin: 0 0 0 5px;
    width: 8px;
  }
`

export const MenuDropDown = styled.div`
  z-index: 1001;
  position: absolute;

  top: 2.5rem;
  right: 0;

  width: auto;
  padding: 1rem 0;
  white-space: nowrap;

  background: #ffffff;
  border-radius: 1rem;
  box-shadow: 0 5px 30px rgba(172, 182, 191, 0.25);

  display: none;
  &.opened {
    display: block;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    & > li {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      height: 3rem;
      color: #454a54;
      padding: 0 2rem;

      :hover {
        background: #eff3f8;
      }
    }
  }
`
