import { Link } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'

import { BackIcon } from '../../icons'
import { InputDateFields } from '../../components'
import { Button, ErrorDisplay, Input } from 'mmfintech-portal-commons'
import { BackWrapper, CancelContainer, MethodWrapper, PaymentContent } from './Checkout.styled'

import { fixDateOnly, getCountryAlpha2, isEmptyString, tr } from 'mmfintech-commons'

import { CheckoutMethodField, SupportedBank } from 'mmfintech-commons-types'

type AdditionalFieldsStepProps = {
  error?: any
  backVisible: boolean
  onBack: () => void
  countryCode?: string
  paymentMethod: string
  fields: CheckoutMethodField[]
  formValues: any
  submitCaption: string
  onSubmit: () => void
  cancelVisible: boolean
  onCancel: () => void
}

export const AdditionalFieldsStep = ({
  error,
  backVisible,
  onBack,
  countryCode,
  paymentMethod,
  fields,
  formValues,
  submitCaption,
  onSubmit,
  cancelVisible,
  onCancel
}: AdditionalFieldsStepProps) => {
  const { banks, checkoutCurrencies, checkoutPayFetching } = useSelector(
    ({ checkout: { banks, checkoutCurrencies, checkoutPayFetching } }) => ({
      banks,
      checkoutCurrencies,
      checkoutPayFetching
    }),
    shallowEqual
  )

  const getExpiryDate = (): string => {
    const res = []
    const mm = formValues.getValue('expiryMonth')
    if (mm) {
      res.push(mm)
      const yy = formValues.getValue('expiryYear')
      if (yy) {
        res.push(yy)
      }
    }
    return res.join('/')
  }

  const handleExpiryDateChanged = (_name: string, value: string): void => {
    const v = value.replace(/\D/gi, '')
    formValues.setValue('expiryMonth', v.substring(0, 2))
    formValues.setValue('expiryYear', v.substring(2, 4))
  }

  // useEffect(() => {
  //     if (!(Array.isArray(fields) && fields.length > 0)) {
  //       typeof onSubmit === 'function' && onSubmit()
  //     }
  // }, [fields])

  return (
    <PaymentContent>
      {backVisible ? (
        <BackWrapper>
          <span onClick={onBack} data-test='back-button'>
            <BackIcon />
            <span>{tr('CHECKOUT.PAYMENT.SWITCH_METHOD', 'Switch method')}</span>
          </span>
        </BackWrapper>
      ) : null}

      {paymentMethod ? (
        <MethodWrapper data-test={`pay-with-${paymentMethod}-fields`}>
          {tr('CHECKOUT.PAYMENT.PAY_BY', 'Pay with {{METHOD}}', {
            METHOD: tr('METADATA.PAYMENT_OPTIONS.' + paymentMethod, paymentMethod)
          })}
        </MethodWrapper>
      ) : null}

      <ErrorDisplay error={error} />

      {Array.isArray(fields) && fields.length
        ? fields.map((item, index) => {
            const { name, type, defaultLabel, translationKey, required, sensitive, options } = item

            if (name === 'bankChoiceId' || name === 'expiryYear') {
              return null
            }

            if (name === 'expiryMonth') {
              return (
                <Input
                  key={index}
                  type='text'
                  name='expiryDate'
                  data-test={`expiryDate-input`}
                  label={tr('MERCHANTS.CHECKOUT.FIELDS.GENERAL.EXPIRY_DATE', 'Expiry date')}
                  error={formValues.getError('expiryMonth') || formValues.getError('expiryYear')}
                  value={getExpiryDate()}
                  required={true}
                  placeholder={tr('MERCHANTS.CHECKOUT.FIELDS.GENERAL.EXPIRY_DATE_PLACEHOLDER', 'MM/YY')}
                  onChange={handleExpiryDateChanged}
                  hideErrorLine
                  hideShowPassword
                  autoComplete='off'
                />
              )
            }

            if (name === 'swiftCode' && paymentMethod === 'INTERAC') {
              return (
                <Input
                  key={index}
                  type='select'
                  data-test='interac-banks-select'
                  options={banks?.map((bank: SupportedBank) => {
                    const { bankId, name, nameEnglish } = bank
                    return { value: bankId, label: nameEnglish || name }
                  })}
                  label={tr('MERCHANTS.CHECKOUT.FIELDS.GENERAL.BANK', 'Bank')}
                  {...formValues.registerInput(name)}
                  required={required}
                  hideErrorLine
                />
              )
            }

            if (name === 'phone') {
              return (
                <Input
                  type='phone'
                  data-test={`${name}-input`}
                  label={tr(translationKey, defaultLabel)}
                  {...formValues.registerInput(name)}
                  required={required}
                  hideErrorLine
                  autoComplete='off'
                  {...(paymentMethod === 'INTERAC'
                    ? {
                        country: 'ca',
                        onlyCountries: ['ca'],
                        countryCodeEditable: false,
                        disableDropdown: true
                      }
                    : {
                        country: getCountryAlpha2(countryCode)?.toLowerCase()
                      })}
                />
              )
            }

            if (name === 'documentId' && paymentMethod === 'PIX') {
              return (
                <Input
                  key={index}
                  type={type || 'text'}
                  label={tr('MERCHANTS.CHECKOUT.FIELDS.GENERAL.CPF_CNPJ', tr(translationKey, defaultLabel))}
                  data-test='pix-document-id-input'
                  {...formValues.registerShort(name)}
                  onChange={(name: string, value: string) => formValues.setValue(name, value.replace(/[ ./-]/g, ''))}
                  required={required}
                  hideErrorLine
                  autoComplete='off'
                />
              )
            }

            if (name === 'currency') {
              return (
                <Input
                  key={index}
                  type='select'
                  data-test='currency-input'
                  label={tr(translationKey, defaultLabel)}
                  {...formValues.registerInput(name)}
                  options={checkoutCurrencies?.map((currency: string) => ({ value: currency, label: currency }))}
                  required={required}
                  hideErrorLine
                />
              )
            }

            if (type.toLowerCase() === 'date') {
              return (
                <InputDateFields
                  label={tr(translationKey, defaultLabel)}
                  value={formValues.getValue(name) ? new Date(formValues.getValue(name)) : null}
                  setValue={value => formValues.setValue(name, fixDateOnly(value))}
                  required={required}
                  error={formValues.getError(name)}
                  setError={value => formValues.setError(name, value)}
                />
                // <Input
                //   type='date'
                //   data-test={`${name}-input`}
                //   label={tr(translationKey, defaultLabel)}
                //   {...formValues.registerShort(name)}
                //   onChange={(name: string, value: any) => {
                //     formValues.setValue(name, fixDateOnly(value))
                //   }}
                //   required={required}
                //   hideErrorLine
                //   autoComplete='off'
                // />
              )
            }

            if (type.toLowerCase() === 'select') {
              const extractedOptions =
                typeof options === 'object' ? Object.entries(options).map(([value, label]) => ({ value, label })) : []
              return (
                <Input
                  key={index}
                  type='select'
                  data-test={`${name}-select`}
                  options={extractedOptions}
                  label={tr(translationKey, defaultLabel)}
                  {...formValues.registerInput(name)}
                  required={required}
                  hideErrorLine
                />
              )
            }

            return (
              <Input
                key={index}
                type={sensitive ? 'password' : 'text'}
                data-test={`${name}-input`}
                label={tr(translationKey, defaultLabel)}
                {...formValues.registerInput(name)}
                required={required}
                hideErrorLine
                hideShowPassword
                autoComplete='off'
              />
            )
          })
        : null}

      <div className='buttons'>
        <Button
          type='button'
          color='primary'
          text={submitCaption}
          onClick={onSubmit}
          disabled={isEmptyString(paymentMethod)}
          loading={checkoutPayFetching}
          data-test='button-pay'
        />

        {cancelVisible ? (
          <CancelContainer className='mt-1'>
            <Link to='#' onClick={onCancel} data-test='cancel-button'>
              {tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')}
            </Link>
          </CancelContainer>
        ) : null}
      </div>
    </PaymentContent>
  )
}
