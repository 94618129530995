import styled from 'styled-components'

export const AmountContainer = styled.div`
  width: 100%;
  margin-top: -3rem;
  padding: 1.6rem 1.2rem 3rem;

  @media (min-width: 500px) {
    margin-top: -2rem;
    padding: 1.6rem 4rem 3rem;
  }

  background: linear-gradient(180deg, #e3eaf3 66.3%, #d6dde6 100%);
  border-radius: 1.4rem 1.4rem 0 0;
  box-shadow: 0 5px 20px 0 rgba(172, 182, 191, 0.25);

  color: #454a54;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  a {
    color: #2e58d4;
  }

  .row {
    text-align: right;

    margin-top: 0.8rem;
    &:first-of-type {
      margin-top: 0;
    }

    &.amount {
      color: #000;
      font-size: 3.6rem;
      font-style: normal;
      font-weight: 400;
      line-height: 4rem;

      .currency {
        color: #454a54;
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-left: 0.5rem;
        vertical-align: super;
      }
    }

    .label {
      color: #b4bfcb;
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-right: 0.5rem;
    }
  }
`

export const PaymentContainer = styled.div`
  position: relative;
  border-radius: 1rem;
  background: #fff;
  box-shadow: 0 4px 20px 0 rgba(172, 182, 191, 0.2);
  margin-top: -2rem;
  padding: 2.4rem 1.2rem;

  .buttons {
    margin-top: 2.4rem;
  }

  @media (min-width: 500px) {
    padding: 4rem;

    .buttons {
      margin-top: 4rem;
    }
  }
`

export const PaymentContent = styled.div`
  .bank-search {
    display: flex;
    flex-flow: row nowrap;
    //border-bottom: 1px solid #e1e5ea;
    z-index: 100;

    .input-wrapper {
      margin: 0;
      & > div {
        border: none;
        border-radius: 0;
      }
    }

    svg {
      margin-top: 1rem;
    }
  }
`

export const BackWrapper = styled.div`
  text-align: right;
  margin-bottom: 2.4rem;

  span {
    cursor: pointer;
    color: #a3b1bf;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    svg {
      margin-right: 1rem;
    }
  }
`

export const MethodWrapper = styled.div`
  color: #454a54;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 2.4rem;

  @media (min-width: 500px) {
    font-size: 2.2rem;
    margin-bottom: 4rem;
  }
`

export const CancelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  //height: 3rem;
  width: 100%;
  max-width: 40rem;

  a {
    color: #a3b1bf;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration: none;
  }

  @media (min-width: 500px) {
    height: 5rem;
  }
`

export const ProblemWrapper = styled.div`
  margin: 3rem 0 1rem;
  text-align: center;

  & > span {
    cursor: pointer;
    color: #454a54;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-bottom: 1px solid #454a54;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-right: 6px;
    }

    &:hover {
      border-bottom-color: #1e63ff;
      color: #1e63ff;

      svg > * {
        fill: #1e63ff;
      }
    }
  }
`
