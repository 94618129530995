import styled from 'styled-components'

export const WarningWrapper = styled.div`
  color: #454a54;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.7rem;
`

export const WarningContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 0 4rem;
  margin-bottom: 4rem;
`

export const WarningTitle = styled.div`
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 2.8rem;
  text-align: center;
  margin: 4rem 0;
`

export const WarningBullet = styled.div`
  width: 100%;
  display: flex;
  gap: 1.5rem;
  flex-flow: row nowrap;
  align-items: center;
  margin: 0 2rem 1.6rem;

  &:last-of-type {
    margin-bottom: 0;
  }
`

export const WarningButtons = styled.div`
  width: 100%;
  text-align: center;

  .button {
    height: 5rem;
    width: 100%;
    max-width: 40rem;

    font-size: 1.6rem;
    font-weight: 500;
  }
`
