import i18next from 'i18next'
import HttpBackend from 'i18next-http-backend'
import ChainedBackend from 'i18next-chained-backend'
import { initReactI18next } from 'react-i18next'

import settings from './settings'

// noinspection SpellCheckingInspection
const options = {
  // debug: true,
  lng: 'en',
  defaultLanguage: 'en',
  supportedLngs: settings.languages,
  fallbackLng: 'en',
  ns: ['translation'],
  defaultNS: 'translation',
  react: {
    useSuspense: true
  },
  backend: {
    backends: [HttpBackend, HttpBackend],
    backendOptions: [{ loadPath: '/i18n/{{lng}}.json' }]
  }
}

void i18next.use(ChainedBackend).use(initReactI18next).init(options)
// .then((t, key) => t(key))
