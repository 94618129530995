import styled from 'styled-components'

import background1 from '../../assets/images/banky-background-v4.png'
import background2 from '../../assets/images/banky-background-v2.jpg'

export const ContentWrapperStyled = styled.div`
  margin: 0;
  padding: 0;
  min-height: 100vh;
  overflow: hidden;
  overflow-y: auto;
  position: relative;

  font-family: Rubik, sans-serif;
  background-color: #f6f7f8;

  .banky-background,
  .banky-background-2 {
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .banky-background {
    //prettier-ignore
    background-image: url("${background1}");
  }
  .banky-background-2 {
    //prettier-ignore
    background-image: url("${background2}");
  }
`
