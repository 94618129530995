import styled from 'styled-components'

export const CustomSelectWrapper = styled.div`
  cursor: pointer;
  padding: 0;
  border-color: #f0f0f0;
  border-style: solid;
  border-width: 1px 0 0;
  &:last-of-type {
    border-width: 1px 0;
  }

  &.bank-inner {
    margin-left: 6rem;

    @media (min-width: 500px) {
      margin-left: 8rem;
    }
  }

  display: grid;
  grid-template-columns: 6.5rem 1fr 7rem;
  justify-content: center;
  grid-gap: 1rem;

  height: 7rem;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: normal;

  &.back {
    grid-template-columns: 7rem 1fr;
    grid-gap: 2rem;
    border-color: transparent;
    margin-bottom: 1rem;
  }

  &.selected {
    background: #e9eef5;
    border-radius: 1rem;
  }

  &.maintenance {
    cursor: not-allowed;
  }

  min-height: 5.5rem;

  .icon {
    height: 100%;
    display: inline-flex;
    align-items: center;
    margin-left: 1rem;

    img {
      max-width: 4.2rem;
      max-height: 4.2rem;
    }
  }

  .arrow {
    background-color: #1e63ff;
    border-radius: 0 1rem 1rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .back-arrow {
    background-color: #d6dee8;
    border-radius: 1rem 0 0 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  @media (min-width: 500px) {
    height: 8rem;
    grid-template-columns: 6.5rem 1fr 8rem;
    &.back {
      grid-template-columns: 8rem 1fr;
    }
  }
`

export const CustomSelectText = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  word-break: break-all;

  .caption {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: normal;
  }

  .warning {
    color: #ee2b2b;
    font-size: 1.2rem;
    font-weight: 500;
  }
`
