import React from 'react'
import { PreviewFrameStyled } from './PreviewFrame.styled'

interface PreviewFrameProps {
  url: string
}

export const PreviewFrame: React.FC<PreviewFrameProps> = ({ url }) => {
  return (
    <PreviewFrameStyled>
      <iframe
        src={url}
        style={{ minHeight: '75vh', width: '100%' }}
        title='Redirect'
        id='1c6110c5-1dc1-478d-8237-a9dac0daedc7'
      />
    </PreviewFrameStyled>
  )
}
